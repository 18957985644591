import api from "@/api";
// import { jwtDecode } from "jwt-decode";
import {
	getLocalStorageReac,
	getSessionStorageReac,
	setLocalStorageReac,
	// setSessionStorageReac,
} from "@/assets/js/helpers";
// import { useToastStore } from "./toast";
// import { baseURL } from "@/api/config";
import type { TSessionUserData } from "@/api/types";

export const useSessionStore = defineStore("session", () => {
	const router = useRouter();
	const userToken = ref("");
	const userData = ref<TSessionUserData | null>();
	const tokenRefreshTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
	const isBusyRenewalToken = ref(false);

	const isLoggedIn = computed(() => !!userToken.value);
	const isUserAdmin = computed(
		() =>
			// userData.value?.rol_name === "admin" ||
			// userData.value?.rol_name === "super-admin",
			true,
	);
	// const userPermissions = computed<string[]>(
	// 	() => userData.value?.permissions || [],
	// );
	// const userImage = computed(() => {
	// 	return getImageUrl(userData.value?.img_id);
	// });
	// function getImageUrl(imageId?: number | string): string | null {
	// 	if (imageId) {
	// 		return `${baseURL}/users/images-get/${imageId}`;
	// 	}
	// 	return null;
	// }

	function saveToken(
		token: string,
		isRemember = false,
		usrData?: TSessionUserData,
	) {
		userToken.value = token;
		// userData.value = token ? jwtDecode(token) : null;
		if (usrData) userData.value = usrData ?? null;

		if (token) {
			// if (isRemember) {
			setLocalStorageReac("user-token", token);
			// } else {
			// 	setSessionStorageReac("user-token", token);
			// }
		} else {
			const sesToken = getSessionStorageReac("user-token");
			const locToken = getLocalStorageReac("user-token");
			sesToken.value = null;
			locToken.value = null;
		}
	}
	async function loadSessionToken() {
		// const sesToken =
		// 	(getSessionStorageReac("user-token").value as string) || "";
		const locToken = (getLocalStorageReac("user-token").value as string) || "";

		const loadSession = async (token: string) => {
			if (typeof token === "string") {
				saveToken(token);
				if (!tokenRefreshTimeout.value) {
					await setupTokenRenewal(true, true, token);
				}
			} else {
				console.error("Invalid token format", token);
			}
		};

		// if (sesToken) {
		// 	await loadSession(sesToken);
		// } else
		if (locToken) {
			await loadSession(locToken);
		}
	}

	async function setupTokenRenewal(
		isRefreshToken = false,
		debug = true,
		token: string = "",
	) {
		if (isBusyRenewalToken.value) {
			// console.warn(":: Busy token, exiting");
			return;
		}

		isBusyRenewalToken.value = true;
		tokenRefreshTimeout.value && clearTimeout(tokenRefreshTimeout.value);
		tokenRefreshTimeout.value = null;

		if (isRefreshToken) {
			await renewToken(token);
		}

		const TIME_TO_REFRESH = 14 * 60 * 1000; // 14 min
		if (debug) {
			console.log(
				`:: Setup token refresh - ${TIME_TO_REFRESH / 60 / 1000}min`,
				isRefreshToken,
			);
		}
		tokenRefreshTimeout.value = setTimeout(() => {
			void setupTokenRenewal(true, true, token);
		}, TIME_TO_REFRESH);
		isBusyRenewalToken.value = false;
	}
	function cancelRenewToken() {
		if (tokenRefreshTimeout.value) {
			clearTimeout(tokenRefreshTimeout.value);
			tokenRefreshTimeout.value = null;
		}
	}
	async function renewToken(tkn: string) {
		if (!userToken.value && tokenRefreshTimeout.value) {
			return;
		}

		try {
			const res = await api.authRenewSession(tkn); // poziv check-session
			const token = res.data.sid;
			console.log(":: token refreshed");
			saveToken(token, false, res.data.user);
		} catch {
			// isLogged postaje false
			userToken.value = "";
		}
	}

	async function setupLogin(
		payload: Parameters<typeof api.authLogin>[0],
		isRemember = false,
	) {
		console.log(payload);
		try {
			const res = await api.authLogin(payload);
			saveToken(res.data.sid, isRemember, res.data.user);
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}
	async function logoutApi(
		showMsg = false,
		shouldRedirect = true,
	): Promise<boolean> {
		const checkRedirectUser = () => {
			if (shouldRedirect) {
				void router.replace("/");
			}
		};

		if (!userToken.value) {
			console.warn(":: User already logged out");
			// useToastStore().openToastWarning("User already logged out");
			checkRedirectUser();
			return false;
		}
		try {
			await api.authLogout(); // pre session.close radim proveru da li je sesija aktivna i prosledjujem sid
			cancelRenewToken();

			if (showMsg) {
				// useToastStore().openToastInfo("User logged out");
				// privremeno resenje
				alert("User logged out");
			}

			checkRedirectUser();

			return true;
		} catch {
			return false;
		} finally {
			saveToken("", false);
		}
	}

	/**
	 * This function is used to check if the current user has specific permission
	 */
	// function can(...keys: string[]) {
	// 	return keys.every((key) => userPermissions.value.includes(key));
	// }

	return {
		isLoggedIn,
		isUserAdmin,
		userData,
		// userImage,
		// can,
		setupLogin,
		saveToken,
		loadSessionToken,
		setupTokenRenewal,
		logoutApi,
		// getImageUrl,
		userToken,
	};
});
