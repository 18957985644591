import { axios } from "./config.js";
import type {
	TAuthVerifyCreate,
	TAuthVerifyResend,
	TAxiosGenRes,
	TBasicType,
	TForgotPass,
	TLoginParam,
	TLoginRes,
	TResetPass,
	TGetArticlesParam,
	TGetArticlesRes,
	TContactUsParam,
	TRemoveArticleParam,
	// TAddArticleParam,
} from "./types";

export default {
	// Auth
	contactUs: (params: TContactUsParam) => axios.post("auth/contact-us", params),
	authLogin: (params: TLoginParam) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/login", params),
	authRegister: (params: any) =>
		axios.post<TAxiosGenRes<TBasicType>>("auth/register", params),
	authRenewSession: (sid: string) =>
		axios.get<TAxiosGenRes<TBasicType>>("auth/check-session", {
			params: { sid },
		}),
	// authLogout: (sid: string) =>
	// 	axios.post<TAxiosGenRes<TBasicType>>("auth/logout", { sid }),
	authLogout: () => axios.get("auth/logout", { validateStatus: null }),
	authVerifyCreate: (params: TAuthVerifyCreate) =>
		axios.post<TLoginRes>("auth/verify", params),
	authVerifyResend: (params: TAuthVerifyResend) =>
		axios.put("auth/verify", params),
	authForgot: (params: TForgotPass) =>
		axios.post("auth/forgot-password", params),
	authResetPass: (params: TResetPass) =>
		axios.put("auth/forgot-password", params),
	// News
	articlesGet: (params: TGetArticlesParam) =>
		axios.get<TGetArticlesRes>("articles/articles-get", { params }),
	articleAdd: (params: any) => axios.post("articles/articles-add", params),
	articleEdit: (params: any) => axios.post("articles/articles-edit", params),
	articleRemove: (params: TRemoveArticleParam) =>
		axios.post("articles/articles-remove", params),
};
