<script setup lang="ts">
// import { useRouter } from "vue-router";
// import { $t } from "@/i18n";
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

definePage({
	name: "HomePage",
});

const store = useDefaultStore();
// const router = useRouter();
const route = useRoute();

useHead({
	title: store.appName,
	meta: [
		{ name: "title", content: store.appName },
		{
			name: "description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ name: "robots", content: store.metaData.robots },
		{
			property: "og:title",
			content: store.appName,
		},
		{
			property: "og:description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ property: "og:site_name", content: store.appName },
		{
			property: "og:url",
			content: store.baseUrl + route.path,
		},
		{ property: "og:type", content: "website" },
	],
});
</script>

<template>
	<div class="landing-page-wrapper">
		<top-section />
		<about-us-section />
		<news-section />
		<sponsors-section></sponsors-section>
	</div>
</template>

<style lang="scss" scoped>
.landing-page-wrapper {
	.title {
		font-size: 75px;
		text-align: center;
		font-weight: bold;
		padding-bottom: 24px;
		border-bottom: 2px solid $border-color1;
		margin-bottom: 24px;
	}

	.contact-section {
		margin-bottom: 85px;
		.contact-title {
			font-size: 56px;
			font-weight: bold;
			display: inline;
			margin: 0 24px;
		}
	}
}
</style>
