<script setup lang="ts">
import { useDefaultStore } from "@/store";
import { $t } from "@/i18n";

definePage({
	name: "AboutPage",
});

const store = useDefaultStore();
const route = useRoute();

useHead({
	title: `${$t("app.aboutUs.title")} | ${store.appName}`,
	meta: [
		{ name: "title", content: `${$t("app.aboutUs.title")} | ${store.appName}` },
		{
			name: "description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ name: "robots", content: store.metaData.robots },
		{
			property: "og:title",
			content: `${$t("app.aboutUs.title")} | ${store.appName}`,
		},
		{
			property: "og:description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ property: "og:site_name", content: store.appName },
		{
			property: "og:url",
			content: store.baseUrl + route.path,
		},
		{ property: "og:type", content: "website" },
	],
});
</script>

<template>
	<div class="about-us-wrapper">
		<about-top></about-top>
		<about-founders></about-founders>
		<about-participants></about-participants>
		<strong-woman></strong-woman>
		<strong-woman2></strong-woman2>
		<about-competition></about-competition>
		<about-tournament></about-tournament>
		<about-mission></about-mission>
	</div>
</template>

<style lang="scss" scoped>
.about-us-wrapper {
	margin-top: $nav-top-bar-sm;
	// padding: 20px;
	box-sizing: border-box;
	// .title {
	// 	font-size: 56px;
	// 	font-weight: bold;
	// 	margin-bottom: 24px;
	// }
	// .content {
	// 	font-size: 28px;
	// }
}
</style>
