<script setup lang="ts">
import { $t } from "@/i18n";
import { useRouter } from "vue-router";
import { useDefaultStore } from "@/store";
// import { useSessionStore } from "@/store/session";
import { type TLangOption } from "../shared/langSwitcher/LangSwitcher.vue";

withDefaults(
	defineProps<{
		showPages?: boolean;
	}>(),
	{
		showPages: true,
	},
);

const defaultStore = useDefaultStore();
// const sessionStore = useSessionStore();
const router = useRouter();
const langSelected = ref<TLangOption | null>(null);
// const isLoggedIn = computed(() => sessionStore.isLoggedIn);
const hasLangSwitcher = computed(() => true);
const activeTab = ref(null);

const navList = computed(() => {
	return [
		{
			name: "homepage",
			label: $t("app.homepage"),
			routeName: "HomePage",
		},
		{
			name: "about",
			label: $t("app.about.title"),
			routeName: "AboutPage",
		},
		{
			name: "news",
			label: $t("app.news.title"),
			routeName: "NewsPage",
		},
		,
		{
			name: "contact",
			label: $t("app.contact.title"),
			routeName: "ContactPage",
		},
	] as const;
});
const filteredNav = computed(() => {
	return navList.value.filter((item) => {
		// if (sessionStore.isUserAdmin) {
		// 	return false;
		// }

		return true;
	});
});

// async function onClickRegister() {
// 	await router.push({
// 		name: "RegisterPage",
// 	});
// }

// function onClickAvatar() {
// 	void defaultStore.goToProfilePage();
// }

async function onClickedMenuItem(item: (typeof filteredNav.value)[0]) {
	defaultStore.isMobileToggled = false;

	// activeTab.value = item;
	// console.log(activeTab.value);

	await router.push({
		name: item.routeName,
	});
}
</script>

<template>
	<header class="header-bar">
		<div class="left-side">
			<router-link
				class="logo-wrapper"
				to="/"
				draggable="false"
			>
				<img
					class="logo"
					src="@/assets/image/logo.png"
					draggable="false"
					alt="logo"
					aria-label="Women Balkan Basketball League"
				/>
			</router-link>
		</div>
		<div class="center">
			<div
				v-if="showPages"
				class="menu"
			>
				<uni-tabs
					:tabs="filteredNav"
					:activeTab="activeTab"
					:can-close-tabs="false"
					:modern-ui="true"
					:is-top-line="false"
					:canDisplayVerticalLine="true"
				></uni-tabs>
			</div>
		</div>
		<div class="right-side">
			<lang-switcher
				v-if="hasLangSwitcher"
				:selected="langSelected"
				:only-toggle="true"
				can-change-router="query"
				:set-storage="false"
				@change="langSelected = $event"
			></lang-switcher>
			<!-- <lang-switcher
				v-if="hasLangSwitcher"
				:selected="langSelected"
				:only-toggle="false"
				:show-caret="true"
				:can-change-router="undefined"
				:set-storage="false"
				@change="langSelected = $event"
			></lang-switcher> -->
			<!-- <div
				v-if="isLoggedIn"
				class="avatar-section"
			>
				<app-avatar @click="onClickAvatar"></app-avatar>
			</div>
			<template v-else>
				<button
					class="alt"
					@click="onClickRegister"
				>
					<span>{{ $t("app.auth.register") }}</span>
				</button>
				<button @click="defaultStore.onClickLoginPage">
					<span>{{ $t("app.auth.login") }}</span>
				</button>
			</template> -->
		</div>
		<div class="mobile-menu-wrap">
			<burger-menu-btn
				:isMobileHeaderToggled="defaultStore.isMobileToggled"
				@toggle-burger="
					defaultStore.isMobileToggled = !defaultStore.isMobileToggled
				"
			></burger-menu-btn>
			<transition name="slide-fade">
				<header-mobile
					v-if="defaultStore.isMobileToggled"
					:navList="filteredNav"
					@click-menu-item="onClickedMenuItem"
					@close="defaultStore.isMobileToggled = false"
				></header-mobile>
			</transition>
		</div>
	</header>
</template>

<style lang="scss" scoped>
.header-bar {
	display: flex;
	gap: 10px;
	background: $bg-color1;
	height: $nav-top-bar-sm;
	min-height: $nav-top-bar-sm;
	padding: 0 30px;

	// TODO 1 See to reintroduce this
	// max-width: min(1500px, 100vw);
	// width: 100%;
	// align-self: center;
	// position: sticky;
	position: absolute; // !!!
	right: 0; // !!!
	left: 0; // !!!
	// width: 100%; // !!!
	top: 0;
	z-index: 10;
	border-bottom: 1px solid rgb(128, 128, 128, 0.1);
	box-sizing: content-box; // Because of the border-bottom

	&::before {
		content: "";
		backdrop-filter: blur(5px);
		position: absolute;
		inset: 0;
		z-index: -1; // To be in the background
	}

	.left-side,
	.center,
	.right-side {
		flex: 1;
		padding: 10px;
		display: flex;
		align-items: center;
		gap: 10px;
		white-space: nowrap;
	}

	.center {
		font-weight: 500;
		align-items: end;
		padding: 0;
		justify-content: center;
	}

	.logo-wrapper {
		display: flex;
		.logo {
			max-height: calc($nav-top-bar-sm - 10px);
		}
	}

	.right-side {
		display: none;
		justify-content: flex-end;
		gap: 15px;

		button {
			width: 150px;
		}
	}

	.menu {
		display: none;
		gap: 10px;
		height: 100%;

		:deep() {
			.uni-tabs-wrap {
				padding: 0;
				height: 100%;
			}

			.uni-tabs {
				// gap: 100px;
				// gap: 10px;
				height: 100%;
			}

			.line-wrapper {
				margin: 0;
				.line {
					height: 5px;
					// border-radius: 0 0 20px 20px;
					filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.1));
				}
			}

			.tab {
				font-size: 12px;
				font-weight: 300;
				font-family: Pelinka, sans-serif;
				padding: 0;
				margin: 0 24px; // !!!!!

				.name {
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
		}

		a {
			color: inherit;
			text-decoration: none;
		}
	}

	.mobile-menu-wrap {
		display: flex;
	}

	.slide-fade-enter-active,
	.slide-fade-leave-active {
		transition: all 0.3s ease;
	}

	.slide-fade-enter,
	.slide-fade-enter-from,
	.slide-fade-leave-to {
		transform: translateY(10px);
		opacity: 0;
	}

	@media screen and (width >= 768px) {
		.logo-wrapper {
			.logo {
				max-height: calc($nav-top-bar - 10px);
			}
		}

		.menu {
			display: flex;

			:deep() {
				.tab {
					font-size: 13px;
				}
			}
		}

		.right-side {
			display: flex;
		}

		.mobile-menu-wrap {
			display: none;
		}
	}

	@media screen and (width >= 1024px) {
		// .menu {
		// 	:deep() {
		// 		.tab {
		// 			font-size: 16px;
		// 			margin: 0 50px;
		// 		}
		// 	}
		// }

		.right-side {
			display: flex;
		}

		.mobile-menu-wrap {
			display: none;
		}
	}

	@media screen and (width >= 1350px) {
		.menu {
			:deep() {
				.tab {
					font-size: 16px;
					margin: 0 50px;
				}
			}
		}
	}
}

@media screen and (width >= 768px) {
	.header-bar {
		height: $nav-top-bar;
		min-height: $nav-top-bar;
	}
}

@media screen and (width >= 1024px) {
	.header-bar {
		padding: 0 100px;
	}
}
</style>
