<script setup lang="ts">
import { useRouter } from "vue-router";

definePage({
	name: "CatchAllPage",
});

let timeout: ReturnType<typeof setTimeout> | null = null;
const router = useRouter();

onMounted(() => {
	timeout = setTimeout(() => {
		void router.replace("/");
	}, 5000);
});
onUnmounted(() => {
	timeout && clearTimeout(timeout);
});
</script>

<template>
	<div>
		<span>Page not found!</span>
		<span>You will be redirected in 5 seconds</span>
	</div>
</template>

<style lang="scss" scoped>
div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	height: 100vh;
	color: rgb(255, 0, 0);
}
</style>
