<script setup lang="ts">
import { $t } from "@/i18n";

const props = withDefaults(
	defineProps<{
		type: "info" | "success" | "warning" | "error";
		isDarkTheme?: boolean;
		titleMsg?: string;
		msg?: string;
	}>(),
	{
		type: "error",
		titleMsg: "",
		msg: "",
	},
);

const parsedTitleMsg = computed(() => {
	if (props.titleMsg) return props.titleMsg;

	switch (props.type) {
		case "info": {
			return $t("app.toast.info");
		}
		case "success": {
			return $t("app.toast.success");
		}
		case "warning": {
			return $t("app.toast.warning");
		}
		case "error": {
			return $t("app.toast.error");
		}
	}

	return "";
});
</script>

<template>
	<div
		class="my-toast"
		:class="[type, { 'dark-theme': isDarkTheme }]"
	>
		<slot>
			<div class="line"></div>
			<div class="content">
				<div class="icon">
					<template v-if="type === 'error'">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="61"
							height="61"
							viewbox="0 0 61 61"
							fill="none"
						>
							<circle
								cx="30.5256"
								cy="30.4348"
								r="29.9309"
								fill="#E44E75"
								fill-opacity="0.1"
							></circle>
							<path
								d="M40.5027 24.4492L26.7844 38.417L20.5488 32.068"
								stroke="#E44E75"
								stroke-width="4"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</template>
					<template v-else-if="type === 'warning'">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="61"
							height="61"
							viewbox="0 0 61 61"
							fill="none"
						>
							<circle
								cx="30.5256"
								cy="30.4348"
								r="29.9309"
								fill="#E44E75"
								fill-opacity="0.1"
							></circle>
							<path
								d="M40.5027 24.4492L26.7844 38.417L20.5488 32.068"
								stroke="#E44E75"
								stroke-width="4"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</template>
					<template v-else-if="type === 'info'">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="61"
							height="61"
							viewbox="0 0 61 61"
							fill="none"
						>
							<circle
								cx="30.5256"
								cy="30.4348"
								r="29.9309"
								fill="#33B980"
								fill-opacity="0.1"
							></circle>
							<path
								d="M40.5027 24.4492L26.7844 38.417L20.5488 32.068"
								stroke="#33B980"
								stroke-width="4"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</template>
					<template v-else-if="type === 'success'">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="61"
							height="61"
							viewbox="0 0 61 61"
							fill="none"
						>
							<circle
								cx="30.5256"
								cy="30.4348"
								r="29.9309"
								fill="#33B980"
								fill-opacity="0.1"
							></circle>
							<path
								d="M40.5027 24.4492L26.7844 38.417L20.5488 32.068"
								stroke="#33B980"
								stroke-width="4"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</template>
				</div>
				<div class="text-content">
					<div class="title">{{ parsedTitleMsg }}</div>
					<div class="text">{{ msg }}</div>
				</div>
			</div>
		</slot>
	</div>
</template>

<style lang="scss">
.Vue-Toastification__toast.my-custom-toast-class {
	$content-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	$border-radius: 6px;

	min-height: 80px;
	background-color: transparent;
	user-select: none;
	border: 0;
	border-radius: $border-radius;
	box-sizing: border-box;
	padding: 0;
	backdrop-filter: blur(20px);
	box-shadow: $content-shadow;
}
</style>
<style lang="scss" scoped>
$content-back: #fff;
$title-color: $text-color;
$content-text: $text-color-light;
$border-color: #47474790;
$success-color: $success-color;
$info-color: $info-color;
$warning-color: $warning-color;
$error-color: $error-color;
$dark-theme-back: #33353b;
$dark-theme-title: white;
$dark-theme-text: #d3d3d3;
$icon-size: 40px;

// $success-color: #47eaaf;

.my-toast {
	display: flex;
	height: 100%;
	width: 100%;
	background-color: $content-back;

	.line {
		height: 100%;
		width: 5px;
	}

	.content {
		padding: 8px 20px;
		display: flex;
		align-items: center;
		gap: 20px;
		width: 100%;
		overflow: hidden;
		cursor: pointer;

		.text-content {
			font-size: 16px;
			font-style: normal;
			display: flex;
			flex-direction: column;
			gap: 5px;

			.title {
				color: $title-color;
				font-weight: 600;
				line-height: 17px;
			}

			.text {
				color: $content-text;
			}
		}

		.icon {
			width: $icon-size;
			height: $icon-size;
			display: grid;
			place-content: center;

			svg {
				transform: scale(0.6);
			}
		}
	}

	&.success {
		.line {
			background: $success-color;
		}
	}
	&.info {
		.line {
			background: $info-color;
		}
	}
	&.warning {
		.line {
			background: $warning-color;
		}
	}
	&.error {
		.line {
			background: $error-color;
		}
	}

	&.dark-theme {
		.content {
			background-color: $dark-theme-back;
			border: 0;

			.title {
				color: $dark-theme-title;
			}

			.text {
				color: $dark-theme-text;
			}
		}
	}
}
</style>
