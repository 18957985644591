<script setup lang="ts">
import {
	getLocalStorageReac,
	getSessionStorageReac,
} from "@/assets/js/helpers";
import { useRouter, useRoute } from "vue-router";
import usFlag from "./usflag.svg";
import srFlag from "./srflag.svg";

export type TLangOption = {
	name: string;
	label: string;
	flag?: string; // URL to flag image (png / jpg)
	svgFlag?: string; // URL to flag image (svg)
};

// const { locale } = useI18n();
const props = withDefaults(
	defineProps<{
		selected?: TLangOption | TLangOption["name"];
		options?: TLangOption[];
		setStorage?: boolean;
		canChangeRouter?: "query" | "params" | null;
		showCaret?: boolean;
		onlyToggle?: boolean;
	}>(),
	{
		selected: undefined,
		options: undefined,
		setStorage: true,
		canChangeRouter: undefined,
	},
);
const emit = defineEmits<{
	change: [value: TLangOption];
}>();

const sessionLang = getSessionStorageReac("locale", false);
const localLang = getLocalStorageReac("locale", false, "sr");
const router = useRouter();
const route = useRoute();
const langSelectedInternal = ref<TLangOption | null>(null);
const defaultOptions = ref<TLangOption[]>([
	{
		name: "en",
		label: "EN",
		svgFlag: usFlag,
	},
	{
		name: "sr",
		label: "SR",
		svgFlag: srFlag,
	},
]);

const langPropParsed = computed<TLangOption | null>(() => {
	if (!props.selected) return null;
	const langName =
		typeof props.selected === "string" ? props.selected : props.selected.name;
	const langObj = checkValidLang(langName);
	return langObj || null;
});
const langSelectedComputed = computed<TLangOption | null>(() => {
	return langPropParsed.value || langSelectedInternal.value;
});
const langOptions = computed(() => {
	return props.options?.length ? props.options : defaultOptions.value;
});

function checkValidLang(langName: TLangOption["name"]): TLangOption | null {
	const langObj = langOptions.value.find(
		(langOpt) => langOpt.name === langName,
	);
	if (langObj) {
		return langObj;
	}
	console.error("Invalid lang specified", langName);
	return null;
}
function initLangSelection() {
	const langStorage = sessionLang.value || localLang.value;
	const routerLang = route.query.lang;
	if (routerLang && props.canChangeRouter) {
		const langObj = checkValidLang(String(routerLang));
		langObj && setLanguage(langObj);
	} else if (langStorage) {
		// console.log(langStorage);
		const langObj = checkValidLang(String(langStorage));
		langObj && setLanguage(langObj);
	} else {
		setLanguage(langOptions.value[0]);
	}
}

function setLanguage(lang: TLangOption) {
	// console.log(":: Language changed to", lang);
	langSelectedInternal.value = lang;
	// set local storage on change
	props.setStorage &&
		(sessionLang.value = langSelectedInternal.value.name) &&
		(localLang.value = langSelectedInternal.value.name);
}
async function onInput(evt: TLangOption["name"]) {
	const langObj = checkValidLang(evt);
	if (langObj) {
		setLanguage(langObj);
		await setLangRouter(langObj);
	}
	emit("change", langObj);
}
async function setLangRouter(langObj: TLangOption) {
	if (props.canChangeRouter) {
		const payload: any = {};
		if (props.canChangeRouter === "query") {
			payload.query = {
				lang: langObj.name,
			};
		} else if (props.canChangeRouter === "params") {
			payload.params = {
				lang: langObj.name,
			};
		} else {
			console.error("Invalid props", props.canChangeRouter);
			return;
		}

		await router.replace(payload);
	}
}
function capitalize(val: string, shouldTransform = false) {
	if (shouldTransform) return val[0].toUpperCase() + val.slice(1).toLowerCase();
	return val;
}

/**
 * Only has affect when using toggle prop
 */
async function onClickWrapper() {
	if (props.onlyToggle) {
		await toggleOptions();
	}
}
async function toggleOptions() {
	const currIndex = langOptions.value.findIndex(
		(opt) => opt.name === langSelectedInternal.value.name,
	);

	// Not selected or last in arr > 0
	const langObj =
		currIndex === -1 || currIndex === langOptions.value.length - 1
			? langOptions.value[0]
			: langOptions.value[currIndex + 1];

	setLanguage(langObj);
	await setLangRouter(langObj);
	emit("change", langObj);
}

defineExpose({
	setLanguage,
	toggleOptions,
});

watch(
	() => langSelectedComputed.value,
	(val) => {
		setLanguage(val);
	},
);

onMounted(() => {
	if (props.selected) {
		const langName =
			typeof props.selected === "string" ? props.selected : props.selected.name;
		const langObj = checkValidLang(langName);
		langObj && setLanguage(langObj);
	} else {
		initLangSelection();
	}
});
</script>

<template>
	<div
		class="lang-switcher"
		:class="{ 'toggle-only': onlyToggle }"
		@click="onClickWrapper"
	>
		<multiselect-form
			:model-value="langSelectedComputed"
			:options="langOptions"
			label="label"
			value-prop="name"
			:caret="showCaret"
			@input="onInput"
		>
			<template #option-single="{ props: propsSingle }">
				<div
					class="item-group"
					style="padding: 0 3px"
				>
					<div class="label">{{ capitalize(propsSingle.option.label) }}</div>
					<img
						v-if="propsSingle.option.flag"
						:src="propsSingle.option.flag"
					/>
					<inline-svg
						v-if="propsSingle.option.svgFlag"
						class="flag"
						:src="propsSingle.option.svgFlag"
					></inline-svg>
				</div>
			</template>
			<template #option-pre="{ props: propsPre }">
				<div class="item-group">
					<div class="label">{{ capitalize(propsPre.option.label) }}</div>
					<img
						v-if="propsPre.option.flag"
						:src="propsPre.option.flag"
					/>
					<inline-svg
						v-if="propsPre.option.svgFlag"
						class="flag"
						:src="propsPre.option.svgFlag"
					></inline-svg>
				</div>
			</template>
		</multiselect-form>
	</div>
</template>

<style lang="scss" scoped>
.lang-switcher {
	display: flex;
	// min-width: 60px;

	&.toggle-only {
		cursor: pointer;

		.multiselect-form {
			pointer-events: none;
		}
	}

	.item-group {
		display: flex;
		align-items: center;
		gap: 10px;
		width: 100%;

		.label {
			font-size: 0.7rem;
		}

		.flag {
			max-height: 0.8rem;
			min-width: 20px;
			margin-left: auto;
		}
	}
}
</style>
