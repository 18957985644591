<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
import { useSessionStore } from "@/store/session";
import type { TFilePickerChange } from "src/components/shared/fileUpload/FilePicker.vue";
import { $t } from "@/i18n";
import { useToastStore } from "@/store/toast";
import logo from "../../assets/image/logo.png";
// import utc from "dayjs/plugin/utc";

definePage({
	name: "CreateArticle",
});

// const route = useRoute();
const store = useDefaultStore();
const storeSession = useSessionStore();
const toast = useToastStore();
// const errorMsgBool = ref(false);
// const errorMsgText = ref("");
// const errorMsgTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const coverImagePreviewUrl = ref("");
const slug = ref("");
const route = useRoute();

useHead({
	title: `${$t("app.admin.createArticleTitle")} | ${store.appName}`,
	meta: [
		{
			name: "title",
			content: `${$t("app.admin.createArticleTitle")} | ${store.appName}`,
		},
		{
			name: "description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ name: "robots", content: store.metaData.robotsNoIndex },
		{
			property: "og:title",
			content: `${$t("app.admin.createArticleTitle")} | ${store.appName}`,
		},
		{
			property: "og:description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ property: "og:image", content: logo },
		{ property: "og:site_name", content: store.appName },
		{
			property: "og:url",
			content: store.baseUrl + route.path,
		},
		{ property: "og:type", content: "website" },
	],
});

const form = reactive({
	title: "",
	date: "",
	image: null as File | null,
	// image_alt: null as string | null,
	locale: "empty",
	status: "draft",
	content: "" as string | "",
	livestream: null as string | null,
	description: "",
	sid: storeSession.userToken,
});

const isValidLoginForm = computed(() => {
	const hasFields = !!(
		form.title &&
		form.date &&
		form.locale &&
		form.status &&
		form.content &&
		form.image &&
		// form.image_alt &&
		form.description
	);
	if (form.locale === "empty") return false;
	if (hasFields) return true;
	return false;
});

// File change

function handleCoverFileChange(event: TFilePickerChange) {
	form.image = event.files ? event.files[0] : null;

	if (event.files && event.files?.length > 0) {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			coverImagePreviewUrl.value = reader.result as string;
		});
		reader.readAsDataURL(event.files[0]);
	} else {
		coverImagePreviewUrl.value = "";
	}
}

// Create post

async function onClickCreateArticle() {
	// title check
	// if (form.title) {
	// if (store.textRegex.test(form.title)) {
	slug.value = await store.createSlug(form.title);
	// const slugBool = await store.slugCheck(slug.value);

	// if (!slugBool) {
	// 	toast.openToastError($t("app.admin.errorMsg.titleExists"));
	// 	return false;
	// }
	if (form.content.length > 4_000_000_000) {
		toast.openToastError($t("app.admin.errorMsg.tooLongContent"));
		return false;
	}
	// }
	// else {
	// errorMsgText.value = $t("app.admin.errorMsg.title");
	// errorMsgBool.value = true;
	// 	toast.openToastError($t("app.admin.errorMsg.title"));
	// 	return false;
	// }
	// img name check
	// if (form.image_alt && !store.textRegex.test(form.image_alt)) {
	// errorMsgText.value = $t("app.admin.errorMsg.image_alt");
	// console.log(form.image_alt);
	// errorMsgBool.value = true;
	// 	toast.openToastError($t("app.admin.errorMsg.image_alt"));
	// 	return false;
	// }
	// content check
	// if (!store.textRegex.test(form.content)) {
	// errorMsgText.value = $t("app.admin.errorMsg.content");
	// errorMsgBool.value = true;
	// 	toast.openToastError($t("app.admin.errorMsg.content"));
	// 	return false;
	// }
	// livestream check
	// if (form.livestream && !store.linkRegex.test(form.livestream)) {
	// errorMsgText.value = $t("app.admin.errorMsg.livestream");
	// errorMsgBool.value = true;
	// 	toast.openToastError($t("app.admin.errorMsg.livestream"));
	// 	return false;
	// }

	const formData = new FormData();
	for (const key in form) {
		if (key !== "image" && key !== "date" && key !== "livestream") {
			formData.append(key, (form as Record<string, any>)[key]);
		}
	}
	if (form.image) {
		formData.append("image", form.image);
	}

	// console.log(form.date);
	// console.log(store.formatDatepickerDate(form.date));
	// return;

	// if (form.date) {
	// 	formData.append("date", form.date);
	// }

	// const utcDate = computed(() => {
	// 	const localDate = dayjs(form.date);
	// 	const utcDate = localDate.utc();
	// 	return utcDate.format("YYYY-MM-DD HH:mm:ss");
	// });

	// console.log(form.date);
	// console.log(utcDate.value);

	// return;

	if (form.date) {
		formData.append("date", store.formatDatepickerDate(form.date));
	}

	if (form.livestream && form.livestream !== "null") {
		formData.append("livestream", form.livestream);
	}

	if (slug.value) {
		formData.append("slug", slug.value);
	} else {
		toast.openToastError($t("app.errorMessage"));
		return false;
	}

	for (const entry of formData.entries()) {
		console.log(entry);
	}

	try {
		const res = await api.articleAdd(formData);
		toast.openToastSuccess($t("app.admin.createArticleSuccess"));
		resetForm();
		console.warn(res.data);
	} catch (err: any) {
		// errorMsgBool.value = true;
		// errorMsgText.value = $t("app.errorMessage");
		toast.openToastError($t("app.errorMessage"));
		console.warn(err.message);
	}
}

function resetForm() {
	form.date = "";
	form.title = "";
	form.image = null;
	// form.image_alt = null;
	form.locale = "empty";
	form.status = "draft";
	form.content = "";
	form.livestream = null;
	form.description = "";
}

// watch(errorMsgBool, (newValue, oldValue) => {
// 	if (newValue) {
// 		errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
// 		errorMsgTimeout.value = setTimeout(() => {
// 			errorMsgBool.value = false;
// 		}, 5000);
// 	} else {
// 		errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
// 	}
// });

// onMounted(() => {});
</script>

<template>
	<div class="admin-main-wrapper">
		<div class="admin-main-container">
			<div class="main-title">
				{{ $t("app.admin.createArticleTitle").toUpperCase() }}
			</div>
			<div class="content-wrapper">
				<div class="group">
					<label>
						{{ $t("app.admin.articleForm.title") }}*
						<span>&nbsp;({{ form.title?.length }}/255)</span>
					</label>
					<input
						v-model="form.title"
						type="text"
						maxlength="255"
					/>
				</div>
				<div class="group">
					<label>
						{{ $t("app.admin.articleForm.description") }}*
						<span>&nbsp;({{ form.description?.length }}/120)</span>
					</label>
					<input
						v-model="form.description"
						type="text"
						maxlength="120"
					/>
				</div>
				<div class="group">
					<label>{{ $t("app.admin.articleForm.date") }}*</label>
					<modern-date-picker
						v-model="form.date"
						:textInput="false"
						outputFormatString="YYYY-MM-DD HH:mm:ss"
						:input-icon-right="true"
						:enable-time-picker="true"
						:enable-seconds="true"
					></modern-date-picker>
					<span>Output: {{ form.date }}</span>
				</div>
				<div class="group">
					<label>{{ $t("app.admin.articleForm.image") }}</label>
					<div class="admin-image-choose-wrapper">
						<file-picker
							title="Choose Cover Image"
							placeholder="Select an image file"
							:multiple="false"
							@change="handleCoverFileChange"
						>
							<template #buttonLabel>
								<div class="button-wrapper">
									<span class="full-button admin-file-picker-button-title">
										{{ $t("app.admin.chooseImage") }}
									</span>
								</div>
							</template>
						</file-picker>
						<div class="admin-image-preview-wrapper">
							<img
								v-if="form.image"
								:src="coverImagePreviewUrl"
								alt="Preview Cover Image"
							/>
							<img
								v-else
								:src="store.noImagePath"
								alt="Preview Cover Image"
							/>
						</div>
					</div>
				</div>
				<div class="group">
					<label>
						{{ $t("app.admin.articleForm.livestream") }}
						<span v-if="form.livestream">
							&nbsp;({{ form.livestream?.length }}/255)
						</span>
						<span v-if="!form.livestream">&nbsp;(0/255)</span>
					</label>
					<input
						v-model="form.livestream"
						type="text"
						maxlength="255"
					/>
				</div>
				<div class="group select-wrapper">
					<label>{{ $t("app.admin.articleForm.locale") }}*</label>
					<select
						v-model="form.locale"
						class="select-item"
					>
						<option
							value="empty"
							disabled
						>
							{{ $t("app.admin.articleForm.locale") }}
						</option>
						<option
							v-for="loc in store.validLocales"
							:key="loc"
							:value="loc"
						>
							{{ loc }}
						</option>
					</select>
				</div>
				<div class="group select-wrapper">
					<label>{{ $t("app.admin.articleForm.status") }}*</label>
					<select
						v-model="form.status"
						class="select-item"
					>
						<option
							v-for="(value, index) in store.validStatuses"
							:key="index"
							:value="value"
							:selected="value === 'draft'"
						>
							{{ value }}
						</option>
					</select>
				</div>
				<div class="group">
					<label>{{ $t("app.admin.articleForm.content") }}*</label>
					<textarea
						v-model="form.content"
						maxlength="2147483000"
					></textarea>
				</div>
				<div class="admin-submit">
					<button
						:disabled="!isValidLoginForm"
						class="button-wrapper"
						@click="onClickCreateArticle"
					>
						<span
							class="full-button"
							style="border: 1px solid red; background-color: rgb(0, 0, 0)"
						>
							{{ $t("app.submit") }}
						</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.dp__input_wrap input {
	background-color: rgb(0, 0, 0) !important;
}

.admin-main-wrapper {
	margin-top: $nav-top-bar-sm;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $bg-color1;

	.admin-main-container {
		margin: 24px auto;
		background-color: $background-color;
		padding: 24px;

		.main-title {
			text-align: center;
			font-size: 16px;
			// color: $text-color-lighter;
			// font-weight: lighter;
			font-family: Roboto, sans-serif;
			font-weight: 700;
			margin-bottom: 12px;
		}

		.content-wrapper {
			.group {
				margin-bottom: 12px;
				label {
					color: rgb(148, 155, 173);
				}
				input,
				textarea {
					width: 100%;
					border: 1px solid rgb(221, 223, 228);
					box-shadow: 0 1px 3px 0 #dfdfe8;
					border-radius: unset;
					padding: 8px;
				}

				.admin-image-choose-wrapper {
					.admin-image-preview-wrapper {
						margin-top: 12px;
					}
				}
			}

			.select-wrapper {
				margin: 10px 0;
				.select-item {
					background-color: $background-color;
					width: 100%;
					border: 1px solid rgb(221, 223, 228);
					box-shadow: 0 1px 3px 0 #dfdfe8;
					border-radius: unset;
					padding: 8px;
				}
			}
		}
	}
}

@media screen and (width >= 760px) {
	.admin-main-wrapper {
		.admin-main-container {
			.main-title {
				font-size: 28px;
				line-height: 32px;
			}

			.content-wrapper {
				.group {
					margin-bottom: 12px;
					label {
						color: rgb(148, 155, 173);
					}
					input {
						width: 100%;
						border: 1px solid rgb(221, 223, 228);
						box-shadow: 0 1px 3px 0 #dfdfe8;
						border-radius: unset;
					}
				}
			}
		}
	}
}

@media screen and (width >= 1024px) {
	.admin-main-wrapper {
		.admin-main-container {
			margin: 100px auto;
			.main-title {
				font-size: 34px;
				line-height: 40px;
			}

			.content-wrapper {
				.group {
					margin-bottom: 12px;
					label {
						color: rgb(148, 155, 173);
					}
					input {
						width: 100%;
						border: 1px solid rgb(221, 223, 228);
						box-shadow: 0 1px 3px 0 #dfdfe8;
						border-radius: unset;
					}
				}
			}
		}
	}
}

@media screen and (width >= 1572px) {
	.admin-main-wrapper {
		.admin-main-container {
			.main-title {
				font-size: 30px;
				line-height: 35.16px;
			}

			.content-wrapper {
				.group {
					margin-bottom: 12px;
					label {
						color: rgb(148, 155, 173);
					}
					input {
						width: 100%;
						border: 1px solid rgb(221, 223, 228);
						box-shadow: 0 1px 3px 0 #dfdfe8;
						border-radius: unset;
					}
				}
			}
		}
	}
}
</style>
