/* eslint-disable unicorn/no-unused-properties */
import {
	getLocalStorageReac,
	getSessionStorageReac,
} from "@/assets/js/helpers";
import { useSessionStore } from "@/store/session";
import { useToastStore } from "@/store/toast";
import axios, { type AxiosError, type InternalAxiosRequestConfig } from "axios";

const STATUS_CODE = {
	Unathorized: 401,
	Forbidden: 403,
};

// Handles DEV and PROD
export const baseURL = import.meta.env.VITE_APP_ROOT_API;
const instance = axios.create({
	baseURL,
});
const SHOW_DEBUG = false;
let isBusyLogout = false;

instance.interceptors.request.use((config) => {
	handleRequestInterceptor(config);

	return config;
});
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		handleResponseInterceptor(error);
		return Promise.reject(error);
	},
);

function handleRequestInterceptor(config: InternalAxiosRequestConfig<any>) {
	try {
		const tokenSession = getSessionStorageReac("user-token")?.value as string;
		const tokenLocal = getLocalStorageReac("user-token")?.value as string;
		const tokenData = tokenSession || tokenLocal;
		if (tokenData) {
			if (config.params) {
				config.params.sid = tokenData;
			} else {
				config.params = {
					sid: tokenData,
				};
			}
		}
	} catch (err: any) {
		console.error("ERRor", err.message);
	}
}
function handleResponseInterceptor(error: AxiosError<any, any>) {
	SHOW_DEBUG && console.warn("ERR", error);
	if (error.response?.status === STATUS_CODE.Unathorized) {
		// Forbidden - Logout and return to main page
		// eslint-disable-next-line unicorn/no-lonely-if
		if (!isBusyLogout) {
			isBusyLogout = true;
			void useSessionStore().logoutApi();
			useToastStore().openToastWarning(
				"Session expired. Please login to continue",
			);
			setTimeout(() => {
				isBusyLogout = false;
			}, 1000);
		}
	}
}

export { instance as axios };
