<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
// import type { IArticleItem } from "@/components/landingPage/NewsSection.vue";
// import { useRouter } from "vue-router";
import { $t } from "@/i18n";
import { useToastStore } from "@/store/toast";

definePage({
	name: "ContactPage",
	meta: {
		name: "Kontakt",
	},
});

const store = useDefaultStore();
const toast = useToastStore();
const route = useRoute();
const name = ref("");
const email = ref("");
const subject = ref("");
const message = ref("");
const terms = ref(false);

useHead({
	title: `${$t("app.contact.title")} | ${store.appName}`,
	meta: [
		{ name: "title", content: `${$t("app.contact.title")} | ${store.appName}` },
		{
			name: "description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ name: "robots", content: store.metaData.robots },
		{
			property: "og:title",
			content: `${$t("app.contact.title")} | ${store.appName}`,
		},
		{
			property: "og:description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ property: "og:site_name", content: store.appName },
		{
			property: "og:url",
			content: store.baseUrl + route.path,
		},
		{ property: "og:type", content: "website" },
	],
});

// Send email

async function sendMail() {
	if (!terms.value) {
		toast.openToastError($t("app.contact.errorMsg.terms"));
		return false;
	}

	if (!isValidMailForm.value) {
		toast.openToastError($t("app.contact.errorMsg.required"));
		return false;
	}
	const params = {
		name: name.value,
		email: email.value,
		subject: subject.value,
		message: message.value,
	};
	try {
		const res = await api.contactUs(params);
		// console.log(res.data.res);
		console.warn(res.data.res);
		toast.openToastSuccess($t("app.contact.successMsg"));
		name.value = "";
		email.value = "";
		subject.value = "";
		message.value = "";
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

const isValidMailForm = computed(() => {
	const hasFields = !!(
		name.value &&
		email.value &&
		subject.value &&
		message.value
	);
	if (hasFields) return true;
	return false;
});
</script>

<template>
	<div class="contact-wrapper">
		<div class="contact-cover">
			<img
				src="@/assets/image/contact/contact-cover.png"
				alt="cover"
			/>
			<div class="cover-content-wrapper">
				<div class="main">
					{{ $t("app.contact.title").toUpperCase() }}
				</div>
			</div>
		</div>
		<div class="contact-content-wrapper">
			<div class="contact-content-left">
				<div class="contact-content-left-title">
					{{ $t("app.contact.contactUs") }}
				</div>
				<div class="contact-content-left-text">
					{{ $t("app.contact.text") }}
				</div>
				<div class="contact-content-left-contact-info-wrapper">
					<div class="contact-content-left-contact-info-email-wrapper">
						<div class="contact-email-icon">
							<img
								src="@/assets/image/contact/email.png"
								alt="email"
							/>
						</div>
						<div class="contact-info-wrapper">
							<div class="contact-email-title">
								{{ $t("app.contact.email").toUpperCase() }}
							</div>
							<a
								class="contact-email-email"
								href="mailto:email.wbbl.liga@gmail.com"
							>
								email.wbbl.liga@gmail.com
							</a>
						</div>
					</div>
					<div class="contact-content-left-contact-info-phone-wrapper">
						<div class="contact-phone-icon">
							<img
								src="@/assets/image/contact/phone.png"
								alt="phone"
							/>
						</div>
						<div class="contact-info-wrapper">
							<div class="contact-phone-title">
								{{ $t("app.contact.phone").toUpperCase() }}
							</div>
							<div class="contact-phone-phone">
								<a href="tel:+381648655111">+381648655111</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="contact-content-right">
				<div class="contact-content-right-container">
					<div class="contact-content-right-title">
						{{ $t("app.contact.send").toUpperCase() }}
					</div>
					<div class="contact-content-right-group">
						<label
							class="contact-label"
							for="name"
						>
							{{ $t("app.contact.name") }}
						</label>
						<input
							id="name"
							v-model="name"
							class="contact-input"
							type="text"
						/>
					</div>
					<div class="contact-content-right-group">
						<label
							class="contact-label"
							for="email"
						>
							{{ $t("app.contact.email") }}
						</label>
						<input
							id="email"
							v-model="email"
							class="contact-input"
							type="text"
						/>
					</div>
					<div class="contact-content-right-group">
						<label
							class="contact-label"
							for="title"
						>
							{{ $t("app.contact.subject") }}
						</label>
						<input
							id="title"
							v-model="subject"
							class="contact-input"
							type="text"
						/>
					</div>
					<div class="contact-content-right-group">
						<label
							class="contact-label"
							for="message"
						>
							{{ $t("app.contact.message") }}
						</label>
						<textarea
							id="message"
							v-model="message"
							class="contact-input"
							type="text"
						></textarea>
					</div>
					<div class="contact-content-right-terms">
						<input
							id="terms"
							v-model="terms"
							type="checkbox"
						/>
						<label
							for="terms"
							class="contact-content-right-terms-text"
						>
							{{ $t("app.contact.termsText") }}
						</label>
					</div>
					<div
						class="button-wrapper"
						@click="sendMail"
					>
						<div class="full-button">{{ $t("app.contact.buttonText") }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.contact-wrapper {
	margin-top: $nav-top-bar-sm;
	background-color: $bg-color1;

	.contact-cover {
		position: relative;
		aspect-ratio: 100 / 33;
		img {
			width: 100%;
		}
		.cover-content-wrapper {
			position: absolute;
			left: 0;
			bottom: 6%;
			color: $text-color-lighter;
			margin-left: 24px;

			.main {
				line-height: 42px;
				font-weight: bold;
				font-size: 36px;
				color: transparent;
				-webkit-text-stroke: 1px $text-color-lighter;
				transform: translateX(-50px);
				opacity: 0;
				animation: slide-from-side 1s 0s forwards;
			}
		}
	}
	.contact-content-wrapper {
		padding: 24px;
		.contact-content-left {
			margin-bottom: 24px;
			.contact-content-left-title {
				font-size: 26px;
				font-weight: bold;
				line-height: 28px;
				text-align: center;
				text-transform: uppercase;
				padding-bottom: 24px;
				transform: translateX(50px);
				opacity: 0;
				animation: slide-from-side-r 1s 0s forwards;
			}
			.contact-content-left-text {
				text-align: justify;
				font-size: 16px;
				font-family: Roboto, sans-serif;
				transform: translateX(50px);
				opacity: 0;
				animation: slide-from-side-r 1s 0s forwards;
				margin-bottom: 24px;
			}
			.contact-content-left-contact-info-wrapper {
				transform: translateX(50px);
				opacity: 0;
				animation: slide-from-side-r 1s 0s forwards;
				.contact-content-left-contact-info-email-wrapper,
				.contact-content-left-contact-info-phone-wrapper {
					overflow: hidden;
					width: 100%;
					height: 312px;
					background-color: rgb(255, 255, 255);
					display: flex;
					justify-content: start;
					align-items: center;
					flex-direction: column;
					.contact-email-icon,
					.contact-phone-icon {
						width: 75px;
						display: flex;
						justify-content: center;
						align-items: end;
						height: 40%;
					}

					.contact-info-wrapper {
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						padding-top: 15%;
						.contact-email-title,
						.contact-phone-title {
							font-size: 16px;
							font-family: Roboto, sans-serif;
							font-weight: 700;
						}

						.contact-phone-phone {
							font-size: 16px;
							font-family: Roboto, sans-serif;

							a:hover {
								color: $app-accent-color1;
							}
						}

						.contact-email-email {
							&:hover {
								color: $app-accent-color1;
							}
						}
					}
				}

				.contact-content-left-contact-info-email-wrapper {
					margin-bottom: 24px;
				}
			}
		}

		.contact-content-right {
			display: flex;
			justify-content: center;
			margin-top: 50px;
			opacity: 0;
			animation: appear-from-bot 1s 0s forwards;
			.contact-content-right-container {
				padding: 24px;
				width: 100%;
				background-color: rgb(255, 255, 255);
				display: flex;
				flex-direction: column;
				justify-content: center;

				.button-wrapper {
					justify-content: center;
					margin-top: 24px;
				}

				.contact-content-right-title {
					text-align: center;
					font-size: 16px;
					font-family: Roboto, sans-serif;
					font-weight: 700;
					margin-bottom: 12px;
				}

				.contact-content-right-group {
					margin-bottom: 12px;
					.contact-label {
						color: rgb(148, 155, 173);
					}
					.contact-input {
						width: 100%;
						border: 1px solid rgb(221, 223, 228);
						box-shadow: 0 1px 3px 0 #dfdfe8;
						border-radius: unset;
					}

					textarea {
						padding: 0 10px;
					}
				}

				.contact-content-right-terms {
					display: flex;
					align-items: start;
					.contact-content-right-terms-text {
						margin-left: 8px;
						color: rgb(41, 41, 41);
						font-size: 10px;
					}
				}
			}
		}
	}
}

@media screen and (width >= 600px) {
	.contact-wrapper {
		.contact-content-wrapper {
			.contact-content-left {
				.contact-content-left-text {
					margin-right: 24px;
				}
				.contact-content-left-contact-info-wrapper {
					display: flex;
					justify-content: space-between;
					.contact-content-left-contact-info-email-wrapper,
					.contact-content-left-contact-info-phone-wrapper {
						width: 49%;
					}

					.contact-content-left-contact-info-phone-wrapper {
						border-right: 1px solid $bg-color1;
					}
				}
			}
		}
	}
}

@media screen and (width >= 760px) {
	.contact-wrapper {
		.contact-cover {
			.cover-content-wrapper {
				bottom: 30%;

				.main {
					line-height: 84px;
					font-size: 80px;
					-webkit-text-stroke: 2px $text-color-lighter;
				}
			}
		}
		.contact-content-wrapper {
			.contact-content-left {
				.contact-content-left-title {
					font-size: 28px;
					line-height: 32px;
					padding: 24px 0;
					margin: 0;
					transform: translateX(-50px);
					opacity: 0;
					animation: slide-from-side 1s 0s forwards;
				}
				.contact-content-left-text {
					font-size: 18px;
					transform: translateX(-50px);
					opacity: 0;
					animation: slide-from-side 1s 0s forwards;
				}
				.contact-content-left-contact-info-wrapper {
					.contact-content-left-contact-info-email-wrapper,
					.contact-content-left-contact-info-phone-wrapper {
						.contact-email-title,
						.contact-phone-title {
							font-size: 18px;
						}

						.contact-email-email,
						.contact-phone-phone {
							font-size: 18px;
							line-height: 24px;
						}
					}
				}
			}

			.contact-content-right {
				display: flex;
				justify-content: center;
				.contact-content-right-container {
					width: 50%;
					height: 100%;
					.contact-content-right-title {
						font-size: 28px;
						line-height: 32px;
					}
				}
			}
		}
	}
}

@media screen and (width >= 1024px) {
	.contact-wrapper {
		.contact-cover {
			.cover-content-wrapper {
				margin-left: 100px;
			}
		}
	}
}

@media screen and (width >= 1350px) {
	.contact-wrapper {
		.contact-cover {
			.cover-content-wrapper {
				bottom: 30%;

				.main {
					line-height: 108px;
					font-size: 100px;
					-webkit-text-stroke: 3px $text-color-lighter;
				}
			}
		}
		.contact-content-wrapper {
			display: flex;
			padding: 100px;
			.contact-content-left {
				width: 50%;
				margin-bottom: 0;
				.contact-content-left-title {
					font-size: 34px;
					line-height: 40px;
					margin-bottom: 24px;
					padding: 0;
					text-align: left;
				}
				.contact-content-left-text {
					font-size: 30px;
					line-height: 38px;
				}
				.contact-content-left-contact-info-wrapper {
					.contact-content-left-contact-info-email-wrapper,
					.contact-content-left-contact-info-phone-wrapper {
						height: 300px;
						width: 300px;
						.contact-email-title,
						.contact-phone-title {
							font-size: 30px;
							line-height: 38px;
						}

						.contact-email-email,
						.contact-phone-phone {
							font-size: 20px;
							line-height: 26px;
						}
					}

					.contact-content-left-contact-info-email-wrapper {
						border-right: 1px solid $bg-color1;
					}

					.contact-content-left-contact-info-phone-wrapper {
						border-left: 1px solid $bg-color1;
					}
				}
			}

			.contact-content-right {
				width: 50%;
				padding: 24px;
				.contact-content-right-container {
					padding: 36px;
					width: 495px;
					.contact-content-right-title {
						font-size: 34px;
						line-height: 40px;
					}
				}
			}
		}
	}
}

@media screen and (width >= 1572px) {
	.contact-wrapper {
		.contact-cover {
			.cover-content-wrapper {
				margin-left: 100px;
				bottom: 40%;

				.main {
					line-height: 160px;
					font-size: 150px;
					-webkit-text-stroke: 3px $text-color-lighter;
				}
			}
		}
		.contact-content-wrapper {
			.contact-content-left {
				.contact-content-left-text {
					font-size: 30px;
					line-height: 35.16px;
				}
				.contact-content-left-contact-info-wrapper {
					.contact-content-left-contact-info-email-wrapper,
					.contact-content-left-contact-info-phone-wrapper {
						height: 415px;
						width: 415px;
						.contact-email-title,
						.contact-phone-title {
							font-size: 30px;
							line-height: 35.16px;
						}
					}
				}
			}

			.contact-content-right {
				.contact-content-right-container {
					.contact-content-right-title {
						font-size: 30px;
						line-height: 35.16px;
						margin-bottom: 24px;
					}
				}
			}
		}
	}
}
</style>
