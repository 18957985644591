<script setup lang="ts">
definePage({
	name: "AdminPage",
	redirect: "/admin/create-article",
});

const adminSidebarVisible = ref(false);
</script>

<template>
	<!-- <header-bar></header-bar> -->
	<Button
		style="margin-top: 100px"
		@click="adminSidebarVisible = true"
	/>
	<div id="admin-page">
		<!-- <Drawer
			v-model:visible="adminSidebarVisible"
			header="Admin panel"
		>
			<admin-sidebar></admin-sidebar>
		</Drawer> -->
		<router-view></router-view>
	</div>
	<footer-bar></footer-bar>
</template>

<style lang="scss" scoped></style>
