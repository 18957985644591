<script setup lang="ts">
import api from "@/api";
import { useDefaultStore } from "@/store";
// import { useSessionStore } from "@/store/session";
// import { useToastStore } from "@/store/toast";
import type { IArticleItem } from "@/components/landingPage/NewsSection.vue";
import { useRouter } from "vue-router";
import { $t } from "@/i18n";

definePage({
	name: "NewsPage",
});

const store = useDefaultStore();
// const storeSession = useSessionStore();
// const toast = useToastStore();
const articlesList = ref<IArticleItem[] | null>(null);
const router = useRouter();
const latestBool = ref(true);
const showMoreBool = ref(false);
const showLessBool = ref(false);
const currentPage = ref(1);
const limit = ref(7);
const deafultLimit = ref(6);
const route = useRoute();

useHead({
	title: `${$t("app.news.title")} | ${store.appName}`,
	meta: [
		{ name: "title", content: `${$t("app.news.title")} | ${store.appName}` },
		{
			name: "description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ name: "robots", content: store.metaData.robots },
		{
			property: "og:title",
			content: `${$t("app.news.title")} | ${store.appName}`,
		},
		{
			property: "og:description",
			content: `${$t("app.metaInfo.description")}`,
		},
		{ property: "og:site_name", content: store.appName },
		{
			property: "og:url",
			content: store.baseUrl + route.path,
		},
		{ property: "og:type", content: "website" },
	],
});

// Get news

async function getNews() {
	const params = {
		locale: store.activeLocale,
		latest: latestBool.value,
		pastDate: true,
		limit: limit.value,
	};
	try {
		const res = await api.articlesGet(params);
		articlesList.value = res.data.data;
		// console.log(articlesList.value);

		// handle show more

		if (articlesList.value) {
			const n = currentPage.value * deafultLimit.value;
			if (articlesList.value.length > n) {
				const p = articlesList.value.length % n;
				if (p > 0) {
					showMoreBool.value = true;
					articlesList.value = articlesList.value.slice(0, n);
				}
			} else {
				showMoreBool.value = false;
			}
		}

		showLessBool.value = currentPage.value > 1;

		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

// Show more

async function showMore(type: string) {
	if (type == "less") {
		limit.value -= deafultLimit.value;
		currentPage.value -= 1;
	} else if (type == "more") {
		limit.value += deafultLimit.value;
		currentPage.value += 1;
	}

	await getNews();
}

async function toArticle(id: number, slug: string) {
	await router.push({
		name: "ArticlePage",
		params: { id: id, slug: slug },
	});
}

// async function onClickEdit(id: number) {
// 	await router.push({
// 		name: "EditArticle",
// 		params: { id: id },
// 	});
// }

// async function onClickRemove(id: number) {
// 	const res = await store.removeArticle(id);
// 	if (res) {
// 		toast.openToastSuccess($t("app.admin.removePostSuccess"));
// 		await getNews();
// 	} else {
// 		toast.openToastError($t("app.errorMessage"));
// 	}
// }

async function sortByChange() {
	limit.value = 7;
	currentPage.value = 1;
	await getNews();
}

function watchLocaleChange() {
	const unwatch = watch(
		() => store.activeLocale,
		async () => {
			limit.value = 7;
			currentPage.value = 1;
		},
	);
	onBeforeUnmount(() => {
		unwatch();
	});
}

watchEffect(() => {
	void getNews();
	watchLocaleChange();
});
</script>

<template>
	<div class="news-main-wrapper">
		<div class="cover">
			<img
				src="@/assets/image/news-cover.png"
				alt="cover"
			/>
			<div class="cover-content-wrapper">
				<div class="main">
					{{ $t("app.news.coverTextTop").toUpperCase() }}
				</div>
				<div class="main">
					{{ $t("app.news.coverTextBottom").toUpperCase() }}
				</div>
			</div>
		</div>
		<div class="filters-wrapper">
			<div class="latest">
				<label for="latest">{{ $t("app.sortBy.title") }}</label>
				<select
					id="latest"
					v-model="latestBool"
					name="latest"
					@change="sortByChange()"
				>
					<option
						selected
						:value="true"
					>
						{{ $t("app.sortBy.latest") }}
					</option>
					<option :value="false">{{ $t("app.sortBy.oldest") }}</option>
				</select>
			</div>
		</div>
		<div class="news-wrapper">
			<div
				v-for="(article, index) in articlesList"
				:key="index"
				class="news-item"
			>
				<div
					class="cover"
					:style="{ backgroundImage: `url(${article.img_image})` }"
				></div>
				<div class="content-wrapper">
					<div
						class="title"
						@click="toArticle(article.art_id, article.art_slug)"
					>
						{{ article.art_title.toUpperCase() }}
					</div>
					<!-- TEMP -->
					<div
						class="content"
						v-html="article.art_content"
					></div>
					<div class="bottom-wrapper">
						<div
							class="see-more"
							@click="toArticle(article.art_id, article.art_slug)"
						>
							{{ $t("app.buttonText.seeMore").toUpperCase() }}
						</div>
						<p class="date">
							{{ store.formatNewsDate(article.art_utc_date, false) }}
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="show-more-less-wrapper">
			<span
				v-if="showLessBool"
				class="show-more-less-item"
				@click="showMore('less')"
			>
				{{ $t("app.showLess").toUpperCase() }}
			</span>
			<span
				v-if="showMoreBool"
				class="show-more-less-item"
				@click="showMore('more')"
			>
				{{ $t("app.showMore").toUpperCase() }}
			</span>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.news-main-wrapper {
	margin-top: $nav-top-bar-sm;
	box-sizing: border-box;
	background-color: $bg-color1;

	.filters-wrapper {
		font-family: Roboto, sans-serif;
		padding: 24px 24px 0;
		text-align: end;
		font-size: 14px;
		margin-top: 50px;
		opacity: 0;
		animation: appear-from-bot 1s 0s forwards;

		.latest {
			#latest {
				border-right: 12px solid transparent;
				background-color: $background-color;
				width: 160px;
				height: 30px;
				outline: none;
				cursor: pointer;
				margin-left: 8px;
				padding: 0 8px;
			}
		}
	}

	.cover {
		position: relative;
		aspect-ratio: 100 / 33;
		img {
			width: 100%;
		}
		.cover-content-wrapper {
			position: absolute;
			left: 0;
			bottom: 6%;
			color: $text-color-lighter;
			margin-left: 24px;

			.main {
				line-height: 42px;
				font-weight: bold;
				font-size: 36px;
				color: transparent;
				-webkit-text-stroke: 1px $text-color-lighter;
				transform: translateX(-50px);
				opacity: 0;
				animation: slide-from-side 1s 0s forwards;
			}
		}
	}
	.news-wrapper {
		// border: 1px solid red;
		display: grid;
		grid-template-columns: repeat(auto-fit, 1fr);
		gap: 8px;
		box-sizing: border-box;
		padding: 24px 24px 1px;
		justify-items: center;
		align-items: start;
		// min-height: 0;
		// min-width: 0;
		.news-item {
			width: 100%;
			box-sizing: border-box;
			// min-width: 300px;
			// margin-bottom: 2%;
			background-color: $background-color;
			// min-height: 0;
			// min-width: 0;
			// overflow: hidden;

			.cover {
				width: 100%;
				aspect-ratio: 100 / 63;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain !important; // temp
			}
			.content-wrapper {
				padding: 8px;

				.title {
					@include ellipsis;

					-webkit-line-clamp: 3;
					font-size: 16px;
					font-weight: 700;
					line-height: 20px;
					text-align: left;
					margin-bottom: 8px;

					&:hover {
						opacity: 0.7;
						cursor: pointer;
					}
				}

				.content {
					@include ellipsis;

					-webkit-line-clamp: 2;
					font-size: 14px;
					font-weight: 200;
					line-height: 18px;
					text-align: left;
					margin-bottom: 8px;
					font-family: Roboto, sans-serif;
				}

				.bottom-wrapper {
					display: flex;
					justify-content: space-between;
					font-size: 14px;

					.see-more {
						cursor: pointer;
						font-weight: 700;
						color: $app-accent-color1;

						&:hover {
							color: $app-accent-color2;
						}
					}

					.date {
						color: $app-accent-color1;
					}
				}
			}
		}
	}

	.show-more-less-wrapper {
		padding: 0 24px;
		margin-bottom: 24px;
		margin-top: 24px;
		font-size: 14px;
		text-align: right;

		.show-more-less-item {
			cursor: pointer;
			color: $app-accent-color1;
			font-weight: 700;
			margin-left: 18px;

			&:hover {
				color: $app-accent-color2;
			}
		}
	}
}

@media screen and (width >= 580px) {
	.news-main-wrapper {
		.cover {
			.cover-content-wrapper {
				.main {
					line-height: 58px;
					font-size: 50px;
				}
			}
		}
	}
}

@media screen and (width >= 760px) {
	.news-main-wrapper {
		.cover {
			.cover-content-wrapper {
				bottom: 8%;

				.main {
					line-height: 84px;
					font-size: 80px;
					-webkit-text-stroke: 2px $text-color-lighter;
				}
			}
		}
		.news-wrapper {
			grid-template-columns: repeat(2, minmax(200px, 1fr));
			gap: 12px;
			.news-item {
				// min-width: 200px;
				// width: 32%;
				// max-width: 538px;
				box-sizing: border-box;
				// min-width: 300px;
				// margin-bottom: 4%;

				.cover {
					width: 100%;
					aspect-ratio: 100 / 63;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				.content-wrapper {
					.title {
						@include ellipsis;

						-webkit-line-clamp: 3;
						font-size: 16px;
						font-weight: 700;
						line-height: 20px;
						text-align: left;
						margin-bottom: 8px;
						height: 60px;
					}

					.content {
						@include ellipsis;

						-webkit-line-clamp: 2;
						font-size: 14px;
						font-weight: 200;
						line-height: 18px;
						text-align: left;
						margin-bottom: 8px;
						height: 36px;
					}

					.bottom-wrapper {
						display: flex;
						justify-content: space-between;
						color: $app-accent-color1;
						font-size: 14px;

						.see-more {
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}

@media screen and (width >= 1024px) {
	.news-main-wrapper {
		.filters-wrapper {
			padding: 50px 100px 0;
			font-size: 16px;

			.latest {
				#latest {
					border-right: 12px solid transparent;
					background-color: $background-color;
					width: 200px;
					height: 50px;
					outline: none;
					cursor: pointer;
					margin-left: 12px;
					padding: 0 12px;
				}
			}
		}
		.cover {
			.cover-content-wrapper {
				margin-left: 100px;

				.main {
					line-height: 108px;
					font-size: 100px;
					-webkit-text-stroke: 3px $text-color-lighter;
				}
			}
		}
		.news-wrapper {
			padding: 50px 100px 0;
			grid-template: auto / repeat(3, minmax(200px, 1fr));
			gap: 24px;
			.news-item {
				.content-wrapper {
					padding: 16px;
					.title {
						font-size: 20px;
						line-height: 24px;
						margin-bottom: 12px;
						height: 72px;
					}

					.content {
						font-size: 16px;
						line-height: 20px;
						margin-bottom: 12px;
						height: 40px;
					}

					.bottom-wrapper {
						font-size: 16px;
					}
				}
			}
		}

		.show-more-less-wrapper {
			margin-bottom: 50px;
			padding: 0 100px;
			font-size: 16px;
		}
	}
}

@media screen and (width >= 1572px) {
	.news-main-wrapper {
		.cover {
			.cover-content-wrapper {
				margin-left: 100px;
				bottom: 20%;

				.main {
					line-height: 160px;
					font-size: 150px;
					-webkit-text-stroke: 3px $text-color-lighter;
				}
			}
		}
		.news-wrapper {
			.news-item {
				.content-wrapper {
					.title {
						font-size: 30px;
						line-height: 34.5px;
						margin-bottom: 16px;
						height: 104px;
					}

					.content {
						font-size: 18px;
						line-height: 21.09px;
						margin-bottom: 16px;
						height: 43px;
					}

					.bottom-wrapper {
						font-size: 18px;
					}
				}
			}
		}

		.show-more-less-wrapper {
			font-size: 18px;
		}
	}
}
</style>
