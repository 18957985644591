<script setup lang="ts">
// import { useDefaultStore } from "@/store";
import { type IUniTabProp } from "../shared/tabs/UniTabs.vue";
// import { useSessionStore } from "@/store/session";
// import { useRouter } from "vue-router";

defineProps<{
	navList: IUniTabProp[];
}>();

const emit = defineEmits(["click-menu-item", "close"]);
// const defaultStore = useDefaultStore();
// const tokenStore = useSessionStore();
// const router = useRouter();
// const isLoggedIn = computed(() => tokenStore.isLoggedIn);
const hasLangSwitcher = computed(() => true);

// async function onClickLogin() {
// 	emit("close");
// 	await defaultStore.onClickLoginPage();
// }
// async function onClickRegister() {
// 	emit("close");
// 	void router.push({
// 		name: "RegisterPage",
// 	});
// }

// function onClickAvatar() {
// 	emit("close");
// 	void defaultStore.goToProfilePage();
// }
</script>

<template>
	<div class="header-mobile">
		<div class="menu">
			<div
				v-for="navItem in navList"
				:key="navItem.name"
				class="item"
				@click="emit('click-menu-item', navItem)"
			>
				{{ navItem.label }}
			</div>
		</div>
		<div class="action">
			<lang-switcher v-if="hasLangSwitcher"></lang-switcher>
			<!-- <div
				v-if="isLoggedIn"
				class="avatar-section"
			>
				<app-avatar @click="onClickAvatar"></app-avatar>
			</div>
			<template v-else>
				<button
					class="alt"
					@click="onClickRegister"
				>
					<span>{{ $t("app.auth.register") }}</span>
				</button>
				<button @click="onClickLogin">
					<span>{{ $t("app.auth.login") }}</span>
				</button>
			</template> -->
		</div>
	</div>
</template>

<style lang="scss" scoped>
.header-mobile {
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: fixed;
	inset: 0;
	top: $nav-top-bar-sm;
	background-color: var(--color-background);
	color: var(--color-text);
	padding: 20px;
	z-index: 100;
	font-size: 20px;

	.menu {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;

		.item {
			cursor: pointer;

			&:hover {
				filter: brightness(1.5);
			}
		}
	}

	.action {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		margin-top: 30px;

		button {
			width: 200px;
		}
	}
}

@media screen and (width >= 760px) {
	.header-mobile {
		top: $nav-top-bar;
	}
}
</style>
